.m-signature-pad {
    position: relative;
    font-size: 10px;
    width: 700px;
    max-width: 100%;
    height: 400px;
    background-color: #fff;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.m-signature-pad--body {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 60px;
    border-radius: 8px;
    background-color: #d6d6d6;

}

.m-signature-pad--body canvas {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.m-signature-pad--footer {
    position: absolute;
    display: flex;
    justify-content: space-between;
    left: 0px;
    right: 0px;
    bottom: 12px;
    height: 40px;
}

.m-signature-pad--footer .description {
    color: #c3c3c3;
    text-align: center;
    font-size: 1.2em;
    margin-top: 1.8em;
}

.m-signature-pad--footer .button {
    position: absolute;
    bottom: 0;
}

.m-signature-pad--footer .button.clear {
    left: 0;
}

.m-signature-pad--footer .button.save {
    right: 0;
}

@media screen and (max-width: 500px) {
    .m-signature-pad {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        min-width: 320px;
        min-height: 250px;
    }
    #github {
        display: none;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .m-signature-pad {
        width: auto;
        height: auto;
        min-width: 520px;
        min-height: 350px;
    }
}

@media screen and (max-height: 320px) {
    .m-signature-pad--body {
        left: 0;
        right: 0;
        top: 0;
        bottom: 32px;
    }
    .m-signature-pad--footer {
        left: 20px;
        right: 20px;
        bottom: 4px;
        height: 28px;
    }
    .m-signature-pad--footer .description {
        font-size: 1em;
        margin-top: 1em;
    }
}
