@keyframes highlight_border {
	0% {
		outline: 3px transparent solid;
	}
	50% {
		outline: 3px #ffab6e solid;
	}
	100% {
		outline: 3px transparent solid;
	}
}

@keyframes shake {
  0% { 
		transform: rotate(0deg); 
	}
  25% { 
		transform: rotate(5deg); 
	}
  50% { 
		transform: rotate(0eg); 
	}
  75% { 
		transform: rotate(-5deg); 
	}
  100% { 
		transform: rotate(0deg); 
	}
}

@keyframes glow {
	0% {
		color: black;
	}
	50% {
		color: #ffab6e;
	}
	100% {
		color: black;
	}
}

@keyframes glow_background {
	0% {
		background: #f7c1b6;
	}
	50% {
		background: #ADF5FF;
	}
	100% {
		background: #f7c1b6;
	}
}

@keyframes changeColor {
  0% {
    color: black;
  }
  50% {
    color: red;
  }
  100% {
    color: black;
  }
}

.highlight_border {
	animation: highlight_border 0.7s 4 linear both;
}

.highlight_text {
	animation: glow 0.9s 2 linear both;
	background: #f7c1b6;
}

.highlight_field {
	background: #f7c1b6;
}

.highlight_background {
	animation: glow_background 0.9s 2 linear both;
}

.top-menu-scrolled {
	position: fixed;
	top: 0;
	width: 100%;
	left: 48px;
}

.animated-icon {
  animation: shake 0.5s infinite;
}

.color-changing-icon {
  animation: changeColor 1s infinite;
}

.rise-shake {
  animation: jump-shaking 0.83s infinite;
	color: red;
}

@keyframes jump-shaking {
  0% { transform: translateX(0) }
  25% { transform: translateY(-9px) }
  35% { transform: translateY(-9px) rotate(17deg) }
  55% { transform: translateY(-9px) rotate(-17deg) }
  65% { transform: translateY(-9px) rotate(17deg) }
  75% { transform: translateY(-9px) rotate(-17deg) }
  100% { transform: translateY(0) rotate(0) }
}
