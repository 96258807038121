.hidden {
	visibility: hidden;
	opacity: 0;
}

.visible {
	visibility: visible;
	opacity: 1;
}

.grow {
	animation: grow 0.3s linear both;
}

@keyframes grow {
	0% {
			width: 10px;
	}
	100% {
			width: 100%;
	}
}

/* Spinner */
.lds-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #cecece transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
			transform: rotate(0deg);
	}
	100% {
			transform: rotate(360deg);
	}
}

.big-lds-ring {
	display: inline-block;
	width: 240px;
	height: 240px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.big-lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 225px;
	height: 225px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #b867c1 transparent transparent transparent;
}
.big-lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.big-lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.big-lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
			transform: rotate(0deg);
	}
	100% {
			transform: rotate(360deg);
	}
}

/* Mini Spinner */

@keyframes hide {
	0% {
			transform: scale(1);
			width: 32px;
	}
	40% {
			transform: scale(1.5);
	}
	100% {
			transform: scale(0);
			width: 0px;
	}
}

.mini-lds-container {
	position: relative;
	width: 32px;
	height: 32px;
	transition: all 0.2s linear;
}

.hideCheck {
	animation: hide 0.4s ease-in-out forwards 0.1s;
}  

.showCheck {
	transform: scale(1);
	width: 32px;
}

.mini-lds-ring {
	display: inline-block;
	width: 32px;
	height: 32px;
	position: absolute;
	left: 0;
	top: 0;
	/* transform: translate(-50%, -50%); */
}
.mini-lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 24px;
	height: 24px;
	margin: 4px;
	border: 4px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #cecece transparent transparent transparent;
}
.mini-lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.mini-lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.mini-lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
			transform: rotate(0deg);
	}
	100% {
			transform: rotate(360deg);
	}
}


.lds-dual-ring {
	display: inline-block;
	width: 10px;
	height: 10px;
}
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 10px;
	height: 10px;
	margin: 2px;
	border-radius: 50%;
	border: 2px solid red;
	border-color: red transparent red transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}